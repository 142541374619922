<template>
<div v-if="isVisible" class="input-container">
    <label>Nom :</label>
        <input v-model="gameName" @input="filterGames" placeholder="Nom du jeu" class="input-field"
        @keydown.down.prevent="navigateSuggestions('down')"
        @keydown.up.prevent="navigateSuggestions('up')"
        @keydown.enter.prevent="selectHighlightedGame" />
        <div v-if="filteredGames.length" class="autocomplete-list">
            <div v-for="(game, index) in filteredGames" :key="game.id" @click="selectGame(game.name)"
            :class="{'highlighted': index === this.highlightedIndex }" class="autocomplete-item">
            {{ game.name }}
            </div>
    </div>
</div>
</template>

<script>
import {filterList} from '@/database';
export default {
    name: "GamePickerGood",    
    data() {
        return {
            games: [],
            filteredGames: [],
            highlightedIndex: 0,
            gameName : this.name
        }
    },    
    props: {
        name: {
            type: String,
            default : ''
        },
        isVisible: {
            type: Boolean,
            default: false
        }
    },    
    watch: {
        name(newValue) 
        {
            console.log("GOOD RECU :" + newValue)            
            this.gameName = newValue;
        },        
        gameName(newValue) 
        {
            this.$emit('update:gameName', newValue); 
        },
    },    
    methods: {
      
        async filterGames() {
            const query = this.gameName.toLowerCase();
            if (query === '') {
                this.filteredGames = [];
            } else {
               this.filteredGames = await filterList("games","name", query);
            }
            this.highlightedIndex = -1;
            this.$emit('update:gameName', this.gameName); 
        },        
        navigateSuggestions(direction) {
            const suggestions = this.filteredGames;
            if (suggestions.length === 0) return;
            if (direction === 'down') {
                this.highlightedIndex = (this.highlightedIndex + 1) % suggestions.length;
            } else if (direction === 'up') {
                this.highlightedIndex = (this.highlightedIndex - 1 + suggestions.length) % suggestions.length;
            }
        }, 
        selectHighlightedGame() {
            if (this.highlightedIndex !== -1 && this.filteredGames.length > 0) {
                this.selectGame(this.filteredGames[this.highlightedIndex].name);
                this.$emit('update:gameName', this.gameName);
            }
        },   
        selectGame(gameName) {
            this.gameName = gameName;
            this.filteredGames = [];
            this.highlightedIndex = -1;
        }
    }    
}
</script>
<style scoped>

.input-container {
    position: relative; /* Ceci permettra à .autocomplete-list d'être positionné par rapport à cet élément */
    width: 100%;
}

.input-field {
    display: block;
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.autocomplete-list {
    border: 1px solid #ddd;
    border-radius: 4px;
    max-height: 150px;
    overflow-y: auto;
    position: absolute;
    background-color: white;
    width: 100%; /* Utilise 100% de la largeur du conteneur parent */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;
    margin-top: -15px; /* Un petit décalage pour éviter le chevauchement */
}

.autocomplete-item {
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
}

.autocomplete-item:hover,
.highlighted {
    background-color: #e9f5ff;
    color: #007bff;
}

</style>