import axios from "axios";

const API_URL = "https://app.prexens.com";

// Fonction pour décoder le JWT et obtenir les informations du payload
function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

// Vérifier si le token est expiré
export function isTokenExpired(token) {
  if (!token) return true; // Considérer comme expiré s'il n'y a pas de token
  const decoded = parseJwt(token);
  const currentTime = Math.floor(Date.now() / 1000); // Timestamp en secondes
  return decoded.exp < currentTime;
}

// Intercepter les requêtes pour ajouter le token
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");

    var date = new Date();
    date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
    document.cookie = `access_token=${token}; expires=${date.toUTCString()}; path=/`;

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Intercepter les réponses pour gérer le rafraîchissement du token
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const refresh_token = localStorage.getItem("refresh_token");
      const response = await axios.post(`${API_URL}/refreshtoken`, {
        token: refresh_token,
      });
      const { access_token } = response.data;

      // Stocker le nouveau access_token
      localStorage.setItem("access_token", access_token);

      // Réessayer la requête avec le nouveau token
      originalRequest.headers["Authorization"] = `Bearer ${access_token}`;
      return axios(originalRequest);
    }

    return Promise.reject(error);
  }
);
