<template>
    <div v-if="isVisible" class="popup" @keydown.esc="handleEscapeKey" tabindex="0">
        <div class="popup-content">
            <h2>Détails de l'objet</h2>
            <div class="form-section">
                <h3>Modifier un objet</h3>

                <label>Type :</label>
                <input v-model="localItem.type" type="radio" id="console" name="type" value="console" checked
                    class="checkbox-field" />
                <label for="console">Console</label>
                <input v-model="localItem.type" type="radio" id="game" name="type" value="game"
                    class="checkbox-field" />
                <label for="game">Jeu</label>
                <input v-model="localItem.type" type="radio" id="device" name="type" value="device"
                    class="checkbox-field" />
                <label for="device">Périphérique</label><br>

                <div class="form-grid">
                    <div class="form-item">
                        <label>Marque :</label>
                        <select v-model="localItem.brand" class="input-field" :class="{ 'is-invalid': errors.brand }">
                            <option v-for="brand in brands" :key="brand.id" :value="brand.name">{{ brand.name }}
                            </option>
                            <option value="new">+ Ajouter une nouvelle marque</option>
                        </select>
                        <span v-if="errors.brand" class="error-message">{{ errors.brand }}</span>
                        <div v-if="localItem.brand === 'new'" class="form-group">
                            <label>Nouvelle Marque :</label>
                            <input v-model="newBrand" placeholder="Entrer une nouvelle marque" class="input-field">
                        </div>
                    </div>

                    <div class="form-item">
                        <label>Dénomination :</label>
                        <select v-model="localItem.model" class="input-field" :class="{ 'is-invalid': errors.model }">
                            <option v-for="model in models" :key="model.id" :value="model.name">{{ model.name }}
                            </option>
                            <option value="new">+ Ajouter une nouvelle dénomination</option>
                        </select>
                        <span v-if="errors.model" class="error-message">{{ errors.model }}</span>
                        <div v-if="localItem.model === 'new'" class="form-group">
                            <label>Nouvelle Dénomination :</label>
                            <input v-model="newModel" placeholder="Entrer une nouvelle dénomination"
                                class="input-field">
                        </div>
                    </div>

                </div>

                <div class="form-grid">
                    <div class="form-item">
                        <label>Origine :</label>
                        <select v-model="localItem.country" class="input-field"
                            :class="{ 'is-invalid': errors.country }">
                            <option v-for="country in countries" :key="country.id" :value="country.name">{{ country.name
                                }}
                            </option>
                        </select>
                        <span v-if="errors.country" class="error-message">{{ errors.country }}</span>
                    </div>

                    <div class="form-item">
                        <label>État :</label>
                        <select v-model="localItem.condition" class="input-field">
                            <option v-for="condition in conditions" :key="condition.id" :value="condition.name">{{
                                condition.name }}</option>
                        </select>
                    </div>
                </div>

                <div class="form-grid">
                    <div class="form-item">
                        <label>Boite :</label>
                        <select v-model="localItem.boxed" class="input-field">
                            <option value="true">Boîte</option>
                            <option value="false">Sans boîte</option>
                        </select>
                    </div>
                    <div class="form-item">
                        <div class="form-item">
                            <label>Complet :</label>
                            <select v-model="localItem.full" class="input-field">
                                <option value="true">Complet</option>
                                <option value="false">Incomplet</option>
                            </select>
                        </div>
                    </div>
                </div>
                

                <div v-if="localItem.type != 'game'">
                    <label>Titre :</label>
                    <input v-model="localItem.title" placeholder="Nom" class="input-field">
                </div>
                <div v-else>
                    <GamePickerGood :isVisible="true" :name="localItem.name" @update:gameName="updateGameName" />
                </div>

                <label>Description :</label>
                <textarea v-model="localItem.description" placeholder="Description de l'objet" class="input-field"
                    rows="4"></textarea>

                <label for="sold">Vendu :</label>
                <input type="checkbox" id="sold" v-model="localItem.sold" class="checkbox-field"
                    @change="resetSoldPrice" />

                <div class="form-grid-3">
                    <div class="form-item">
                        <label for="soldPrice">Prix de vente :</label>
                        <input type="number" id="soldPrice" v-model="localItem.soldPrice" :disabled="!localItem.sold"
                            placeholder="Entrer le prix de vente" class="input-field" />
                    </div>
                    <div class="form-item">
                        <label for="soldDate">Date de la vente :</label>
                        <input type="date" id="soldDate" v-model="localItem.soldDate" :disabled="!localItem.sold"
                            class="input-field" />
                    </div>
                    <div class="form-item">
                        <label for="soldMethod">Methode :</label>
                        <input type="text" id="soldMethod" v-model="localItem.soldMethod" :disabled="!localItem.sold"
                            class="input-field" />
                    </div>
                </div>

                <div class="form-item">
                    <label>Description :</label>
                    <textarea v-model="localItem.soldDescription" :disabled="!localItem.sold"
                        placeholder="Informations sur la vente" class="input-field" rows="4"></textarea>
                </div>

                <button @click="saveItem" class="btn-add">Mettre à jour</button>&nbsp;
                <button @click="closePopup" class="btn-add">Fermer</button>
            </div>
        </div>
    </div>
</template>

<script>
import config from '@/config/config';
import GamePickerGood from '../common/GamePickerGood.vue';
import axios from "axios";

export default {
    components: {
        GamePickerGood
    },
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        itemId: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            localItem: {},
            tempNewItem: "",
            soldDate: "",
            brands: [], // Liste des marques récupérées depuis l'API
            models: [], // Liste des dénominations récupérées depuis l'API
            conditions: [], // Liste des états récupérés depuis l'API
            countries: [], // Liste des pays récupérés depuis l'API
            newBrand: '', // Nouvelle marque si ajoutée
            newBrandIndex: null, // Index où une nouvelle marque est ajoutée
            newModel: '', // Nouvelle dénomination si ajoutée
            newModelIndex: null, // Index où une nouvelle dénomination est ajoutée
            errors: {
                brand: null,
                model: null
            },
        };
    },
    watch: {
        itemId: {
            async handler(newItemId) {
                if (newItemId != null)
                    await this.loadItem(newItemId)
            },
            immediate: true
        }
    },
    methods: {
        updateGameName(updatedName) {
            this.tempNewItem = updatedName;
        },
        async loadItem(item_key) {
            this.$emit('loading');
            try {
                const response = await axios.get(
                    `${config.apiUrl}/items/${item_key}`
                );

                this.localItem = response.data;

            } catch (error) {
                console.error(
                    "Erreur lors de la récupération des détails l'objet :",
                    error
                );
            } finally {
                this.$emit('loaded');
            }
        },
        resetSoldPrice() {
            if (!this.localItem.sold) {
                this.localItem.soldPrice = null;
            }
        },
        async fetchInitialData() {
            const [brandsRes, modelsRes, conditionsRes, countriesRes] = await Promise.all([
                axios.get(config.apiUrl + '/lists/brands'),
                axios.get(config.apiUrl + '/lists/models'),
                axios.get(config.apiUrl + '/lists/conditions'),
                axios.get(config.apiUrl + '/lists/countries')
            ]);
            this.brands = await brandsRes.data;
            this.models = await modelsRes.data;
            this.conditions = await conditionsRes.data;
            this.countries = await countriesRes.data;
        },
        checkIfNewBrand(index) {
            if (this.items[index].brand === 'new') {
                this.newBrandIndex = index;
            } else {
                this.newBrandIndex = null;
            }
        },
        checkIfNewModel(index) {
            if (this.items[index].model === 'new') {
                this.newModelIndex = index;
            } else {
                this.newModelIndex = null;
            }
        },
        async saveItem() {
            this.$emit('loading');
            if (this.localItem.type == "game" && this.tempNewItem != "")
                this.localItem.name = this.tempNewItem;
            console.log('Objets sauvegardés:', this.localItems);
            const payload = {
                items: [this.localItem]
            };

            // Si une nouvelle marque a été ajoutée
            if (this.newBrand) {
                await fetch('/objects/brands', {
                    method: 'POST',
                    body: JSON.stringify({ name: this.newBrand }),
                    headers: { 'Content-Type': 'application/json' }
                });
            }

            // Si une nouvelle dénomination a été ajoutée
            if (this.newModel) {
                await fetch('/objects/models', {
                    method: 'POST',
                    body: JSON.stringify({ name: this.newModel }),
                    headers: { 'Content-Type': 'application/json' }
                });
            }

            try {
                const response = await axios.post(config.apiUrl + '/items', payload, { headers: { 'Content-Type': 'application/json' } }) // Endpoint pour les marques
                if (response.status > 300) {
                    alert("Une erreur s'est produite lors de l'enregistrement de l'objet.");
                }
                this.closePopup();
            } catch (error) {
                console.error('Erreur :', error);
                alert("Une erreur s'est produite lors de l'enregistrement de l'objet.");
            }
            if (this.localItem.type == "game") this.localItem.title = this.localItem.name
            console.log(this.localItem)
            this.$emit('savedItem', this.localItem);
            this.$emit('loaded');
            this.closePopup();
        },
        closePopup() {
            this.$emit('close');
        },
        handleEscapeKey(event) {
            if (event.key === 'Escape') {
                this.closePopup()
            }
        }
    },
    created() {
        this.fetchInitialData();
    },
    mounted() {
        window.addEventListener('keydown', this.handleEscapeKey);
    },
    beforeUnmount() {
        window.removeEventListener('keydown', this.handleEscapeKey);
    }
};
</script>

<style scoped>
.form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Deux colonnes égales */
    gap: 20px;
    /* Espacement entre les éléments */
}

.form-grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Deux colonnes égales */
    gap: 20px;
    /* Espacement entre les éléments */
}

.form-item {
    display: flex;
    flex-direction: column;
}


.input-field.is-invalid {
    border-color: red;
}

.error-message {
    color: red;
    font-size: 12px;
    display: block;
    margin-top: -13px;
    margin-bottom: 15px;
}

H3 {
    margin: 0px;
    margin-bottom: 10px;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000
}

.popup-content {
    background-color: #fff;
    padding: 20px;
    width: 800px;
    border-radius: 8px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.form-section {
    margin-bottom: 20px;
}

.checkbox-field {
    /* width: 100%; */
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.input-field {
    display: block;
    width: 97%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.input-field-date {
    display: block;
    width: 150px;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.input-field-number {
    display: block;
    width: 150px;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.btn-add,
.btn-save,
.btn-close,
.btn-edit,
.btn-remove {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
}

.btn-remove {
    background-color: #dc3545;
}

.btn-add:hover,
.btn-save:hover,
.btn-close:hover,
.btn-remove:hover {
    opacity: 0.9;
}
</style>