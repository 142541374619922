<template>
    <div v-if="visible" class="popup">
        <div class="popup-content">
            <h2>Gestion des Objets de l'annonce</h2>

            <!-- Liste des objets existants -->
            <div class="item-container" v-if="localItems.length > 0">
                <table class="item-details">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Marque</th>
                            <th>Dénomination</th>
                            <!-- <th>État</th> -->
                            <th>Nom / Description</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in localItems" :key="index">
                            <td>{{ item.type }}</td>
                            <td>{{ item.brand }}</td>
                            <td>{{ item.model }}</td>
                            <!-- <td>{{ item.condition }}</td> -->
                            <td v-if="item.type != 'game'"> {{ item.title }}</td>
                            <td v-else> {{ item.name }}</td>
                            <td style="display: block;">
                                <button @click="editItem(index)" class="btn-edit">Modifier</button>&nbsp;
                                <button @click="removeItem(index)" class="btn-remove">Supprimer</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <hr class="divider">

            <!-- Formulaire pour ajouter un nouvel objet -->
            <div class="form-section">
                <h3>Ajouter un nouvel objet</h3>

                <label>Type :</label>
                <input v-model="newItem.type" type="radio" id="console" name="type" value="console" checked
                    class="checkbox-field" />
                <label for="console">Console</label>

                <input v-model="newItem.type" type="radio" id="game" name="type" value="game" class="checkbox-field" />
                <label for="game">Jeu</label>

                <input v-model="newItem.type" type="radio" id="device" name="type" value="device"
                    class="checkbox-field" />
                <label for="device">Périphérique</label><br>

                <div class="form-grid">
                    <div class="form-item">

                        <label>Marque :</label>
                        <select v-model="newItem.brand" class="input-field" :class="{ 'is-invalid': errors.brand }">
                            <option v-for="brand in brands" :key="brand.id" :value="brand.name">{{ brand.name }}
                            </option>
                            <option value="new">+ Ajouter une nouvelle marque</option>
                        </select>
                        <span v-if="errors.brand" class="error-message">{{ errors.brand }}</span>
                        <div v-if="newItem.brand === 'new'" class="form-group">
                            <label>Nouvelle Marque :</label>
                            <input v-model="newBrand" placeholder="Entrer une nouvelle marque" class="input-field">
                        </div>

                    </div>
                    <div class="form-item">
                        <label>Dénomination :</label>
                        <select v-model="newItem.model" class="input-field" :class="{ 'is-invalid': errors.model }">
                            <option v-for="model in models" :key="model.id" :value="model.name">{{ model.name }}
                            </option>
                            <option value="new">+ Ajouter une nouvelle dénomination</option>
                        </select>
                        <span v-if="errors.model" class="error-message">{{ errors.model }}</span>
                        <div v-if="newItem.model === 'new'" class="form-group">
                            <label>Nouvelle Dénomination :</label>
                            <input v-model="newModel" placeholder="Entrer une nouvelle dénomination"
                                class="input-field">
                        </div>
                    </div>
                </div>

                <div class="form-grid">
                    <div class="form-item">

                        <label>Origine :</label>
                        <select v-model="newItem.country" class="input-field" :class="{ 'is-invalid': errors.country }">
                            <option v-for="country in countries" :key="country.id" :value="country.name">{{ country.name
                                }}
                            </option>
                        </select>
                        <span v-if="errors.country" class="error-message">{{ errors.country }}</span>

                    </div>

                    <div class="form-item">
                        <label>État :</label>
                        <select v-model="newItem.condition" class="input-field">
                            <option v-for="condition in conditions" :key="condition.id" :value="condition.name">{{
                                condition.name }}</option>
                        </select>
                    </div>
                </div>

                <div class="form-grid">
                    <div class="form-item">
                        <label>Boite :</label>
                        <select v-model="newItem.boxed" class="input-field">
                            <option value="true">Boîte</option>
                            <option value="false">Sans boîte</option>
                        </select>
                    </div>
                    <div class="form-item">
                        <div class="form-item">
                            <label>Complet :</label>
                            <select v-model="newItem.full" class="input-field">
                                <option value="true">Complet</option>
                                <option value="false">Incomplet</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div v-if="newItem.type != 'game'">
                    <label>Titre :</label>
                    <input v-model="newItem.title" placeholder="Nom de l'item" class="input-field">
                </div>
                <div v-else>
                    <GamePickerGood :isVisible="true" :name="newItem.name" @update:gameName="updateGameName" />
                </div>

                <label>Description :</label>
                <textarea v-model="newItem.description" placeholder="Description de l'objet"
                    class="input-field"></textarea>
                <button v-if="isEditing" @click="updateItem" class="btn-add">Mettre à jour</button>
                <button v-else @click="addItem" class="btn-add">Ajouter l'objet</button>
            </div>

            <div class="actions">
                <button @click="saveItems" class="btn-save">Enregistrer</button>
                <button @click="closePopup" class="btn-close">Fermer</button>
            </div>
        </div>
    </div>
</template>

<script>
import config from '@/config/config';
import GamePickerGood from '../common/GamePickerGood.vue';
import { isTokenExpired } from '@/auth';
import axios from "axios";

export default {
    components: {
        GamePickerGood
    },
    props: {
        visible: Boolean,
        offer_id: String,
        items: Object
    },
    data() {
        return {
            localItems: [],
            removedItems: [],
            newItem: {},
            newBrand: '',
            newModel: '',
            brands: [],
            models: [],
            conditions: [],
            countries: [],
            isEditing: false,
            editingIndex: -1,
            errors: {
                brand: null,
                model: null
            },
            tempNewItem: ""
        };
    },
    watch: {
        items: {
            handler(newAd) {
                if (newAd) {
                    this.localItems = newAd; // Charger les objets depuis l'annonce
                }
            },
            immediate: true
        }
    },
    methods: {
        updateGameName(updatedName) {
            //this.newItem.name = updatedName;
            this.tempNewItem = updatedName;
        },
        editItem(index) {
            this.newItem = { ...this.localItems[index] };
            // this.newItem.name = this.localItems[index].name;
            this.isEditing = true;
            this.editingIndex = index;
        },
        updateItem() {
            if (this.editingIndex !== -1) {
                this.newItem.name = this.tempNewItem;
                this.localItems.splice(this.editingIndex, 1, { ...this.newItem });
                this.resetForm();
            }
        },
        resetForm() {
            this.newItem = { id: null, brand: '', model: '', condition: '', description: '' };
            this.isEditing = false;
            this.editingIndex = -1;
        },
        validateNewItem() {
            // Reset errors
            this.errors = {
                brand: null,
                model: null
            };

            let isValid = true;

            if (!this.newItem.brand) {
                this.errors.brand = 'La marque est obligatoire.';
                isValid = false;
            }

            if (!this.newItem.model) {
                this.errors.model = 'Le modèle est obligatoire.';
                isValid = false;
            }

            return isValid;
        },
        async fetchInitialData() {
            // Récupérer les listes dynamiques à partir des endpoints
            const [brandsRes, modelsRes, conditionsRes, countriesRes] = await Promise.all([
                axios.get(config.apiUrl + '/lists/brands'), // Endpoint pour les marques
                axios.get(config.apiUrl + '/lists/models'), // Endpoint pour les dénominations
                axios.get(config.apiUrl + '/lists/conditions'), // Endpoint pour les états
                axios.get(config.apiUrl + '/lists/countries') // Endpoint pour les états                
            ]);

            this.countries = await countriesRes.data;
            this.brands = await brandsRes.data;
            this.models = await modelsRes.data;
            this.conditions = await conditionsRes.data;
        },
        async addItem() {

            if (!this.validateNewItem()) {
                return;
            }

            // 1. Vérification et sauvegarde de la nouvelle marque si nécessaire
            if (this.newItem.brand === 'new' && this.newBrand) {
                try {
                    const response = await axios.post(`${config.apiUrl}/lists/brands`, JSON.stringify({ name: this.newBrand }), { headers: { 'Content-Type': 'application/json' } });
                    if (!response.status === 200) {
                        throw new Error('Erreur lors de la création de la nouvelle marque');
                    }
                    const newBrand = await response.data;
                    this.brands.push(newBrand); // Ajouter la nouvelle marque à la liste
                    this.newItem.brand = newBrand.name; // Utiliser la nouvelle marque
                    this.newBrand = ''; // Réinitialiser le champ
                } catch (error) {
                    console.error('Erreur lors de l\'ajout de la marque :', error);
                    return;
                }
            }

            // 2. Vérification et sauvegarde du nouveau modèle si nécessaire
            if (this.newItem.model === 'new' && this.newModel) {
                try {
                    const response = await axios.post(`${config.apiUrl}/lists/models`, JSON.stringify({ name: this.newModel }), { headers: { 'Content-Type': 'application/json' } });
                    if (!response.status === 200) {
                        throw new Error('Erreur lors de la création du nouveau modèle');
                    }
                    const newModel = await response.data;
                    this.models.push(newModel); // Ajouter le nouveau modèle à la liste
                    this.newItem.model = newModel.name; // Utiliser le nouveau modèle
                    this.newModel = ''; // Réinitialiser le champ
                } catch (error) {
                    console.error('Erreur lors de l\'ajout du modèle :', error);
                    return;
                }
            }

            if (!this.localItems) {
                this.localItems = []; // Ensure items is initialized
            }

            // Ajouter un nouvel objet à la liste
            const newObject = { ...this.newItem };

            newObject.name = this.tempNewItem;

            if (newObject.brand === 'new') {
                newObject.brand = this.newBrand; // Utiliser la nouvelle marque
            }

            if (newObject.model === 'new') {
                newObject.model = this.newModel; // Utiliser la nouvelle dénomination
            }

            // Push to local items
            this.localItems.push(newObject);

            // Flush fields
            this.newItem = {
                id: null,
                brand: newObject.brand,
                model: newObject.model,
                condition: newObject.condition,
                country: newObject.country
            };
            this.newBrand = '';
            this.newModel = '';
        },
        removeItem(index) {
            this.removedItems.push(this.localItems[index]);
            this.localItems.splice(index, 1);
        },
        async saveItems() {
            const payload = {
                items: this.localItems,
                removedItems: this.removedItems
            };

            try {
                const response = axios.post(config.apiUrl + '/offers/' + this.offer_id + '/items', JSON.stringify(payload), { headers: { 'Content-Type': 'application/json' } }) // Endpoint pour les marques
                if (!response.status === 200) {
                    throw new Error('Erreur lors de l\'envoi des données');
                }

                const result = await response.data;
                console.log('Succès :', result);
                this.closePopup();
            } catch (error) {
                console.error('Erreur :', error);
            }

            this.closePopup();
        },
        closePopup() {
            this.localItems = []
            this.removedItems = []
            this.$emit('close');
        }
    },
    mounted() {

        const token = localStorage.getItem("access_token");
        if (isTokenExpired(token)) {
            this.$router.push("/");
        }
        this.fetchInitialData(); // Récupérer les marques, dénominations, et états lors du montage
    }
};
</script>

<style scoped>
.form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Deux colonnes égales */
    gap: 20px;
    /* Espacement entre les éléments */
}

.form-item {
    display: flex;
    flex-direction: column;
}

.input-field.is-invalid {
    border-color: red;
}

.error-message {
    color: red;
    font-size: 12px;
    display: block;
    margin-top: -13px;
    margin-bottom: 15px;
}

H3 {
    margin: 0px;
    margin-bottom: 10px;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    background-color: #fff;
    padding: 20px;
    width: 800px;
    border-radius: 8px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.item-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.item-details {
    flex: 1;
}

.divider {
    margin: 20px 0;
    border: 0;
    height: 1px;
    background: #ddd;
}

.form-section {
    margin-bottom: 20px;
}

.checkbox-field {
    /* width: 100%; */
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.input-field {
    display: block;
    width: 98%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.btn-add,
.btn-save,
.btn-close,
.btn-edit,
.btn-remove {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
}

.btn-remove {
    background-color: #dc3545;
}

.btn-add:hover,
.btn-save:hover,
.btn-close:hover,
.btn-remove:hover {
    opacity: 0.9;
}

.actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}
</style>