<template>
  <div class="content">
    <h2>Informations de connexion</h2>
    <form @submit.prevent="login">
      <div>
        <label>Identifiant:</label>
        <input v-model="username" type="text" />
      </div>
      <div>
        <label>Mot de passe:</label>
        <input v-model="password" type="password" />
      </div>
      <button type="submit">Se connecter</button>
    </form>
    <p v-if="errorMessage">{{ errorMessage }}</p>
  </div>
</template>

<script>
import axios from 'axios';
import { isTokenExpired } from '../auth';
import config from '@/config/config';

export default {
  data() {
    return {
      username: '',
      password: '',
      errorMessage: ''
    };
  },
  mounted() {
    const token = localStorage.getItem('access_token');
    if (token && !isTokenExpired(token)) {
      this.$router.push('/dashboard');
    }
  },
  methods: {
    async login() {
      try {
        const response = await axios.post(`${config.apiUrl}/login`, {
          username: this.username,
          password: this.password,
        });

        const { access_token, refresh_token } = response.data;

        // Sauvegarde des tokens dans le localStorage
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('refresh_token', refresh_token);

        this.$router.push('/dashboard');
      } catch (error) {
        this.errorMessage = 'Login failed';
      }
    }
  }
};
</script>

<style scoped>
form {
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

form h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

form label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

form input {
  width: 100%;
  padding: 10px;
  padding-right: 0px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

form button {
  width: 100%;
  padding: 10px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

form button:hover {
  background-color: #2980b9;
}

.content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>