<template>
  <div>
    <!-- Contenu de la page -->
    <LoaderDiv :isLoading="loading" loaderText="Chargement en cours, veuillez patienter..." />
    <OfferDetailPopup :isVisible="isOfferModalVisible" @close="isOfferModalVisible = false" :offer="selectedOffer" />
    <ItemDetailPopup :isVisible="isItemModalVisible" @close="isItemModalVisible = false; selectedItemKey = null"
      :itemId="selectedItemKey" @loading="loading = true" @loaded="loading = false; isItemModalVisible = true"
      @savedItem="savedItem" />

    <h2>Mes objets</h2>
    <div>
      Cette écran affiche la liste des objets que vous détenez
    </div>
    <div class="count">Vous avez {{ totalItems }} objets.</div>

    <div class="main-content-items" ref="mainContentItems" @scroll="handleScroll">

      <!-- Filtres -->
      <div class="filters">

        <!-- Filtre par ID -->
        <label>Offre :</label>
        <input v-model="filters.offerId" placeholder="Offre" class="input-filter" @input="filterItems">

        <!-- Filtre par nom -->
        <label>Contient :</label>
        <input v-model="filters.name" placeholder="Contient" class="input-filter" @input="filterItems">

        <!-- Filtre par type -->
        <label>Plateforme :</label>
        <select v-model="filters.platform" class="input-filter" @change="filterItems">
          <option value="" selected>Toutes</option>
          <option v-for="item in platforms" :key="item.name" :value="item.name">
            {{ item.name }}
          </option>
        </select>

        <!-- Filtre par plateforme -->
        <label>Type :</label>
        <select v-model="filters.type" class="input-filter" @change="filterItems">
          <option value="">Tous les types</option>
          <option value="console">Console</option>
          <option value="device">Device</option>
          <option value="game">Jeux</option>
        </select>

        <!-- Filtre par type -->
        <label>Statut :</label>
        <select v-model="filters.sold" class="input-filter" @change="filterItems">
          <option value="">Tous</option>
          <option value="true">Vendus</option>
          <option value="false">Non vendus</option>
        </select>

      </div>

      <table>
        <thead>
          <tr>
            <th>Titre</th>
            <th>Offre</th>
            <th>Modèle</th>
            <th>Marque</th>
            <th>Vendu</th>
            <th style="width:100px">Actions</th>
          </tr>
        </thead>
        <tbody ref="itemsTable">
          <tr v-for="item in items" :key="item._key">
            <td>
              <a href="#" @click.prevent="showItemDetails(item._key)">{{ item.title }}</a>
            </td>
            <td>
              <a href="#" @click.prevent="showOfferDetails(item.offer_id)"> <img
                  :src="cdnUrl + '/images/' + item.offer_id + '/' + item.offer_id + '_thumbnail.jpg'" width="50px"
                  class="thumbnail">{{ item.offer_id }}</a>
            </td>
            <td>{{ item.model }}</td>
            <td>{{ item.brand }}</td>
            <td>{{ item.sold ? item.soldPrice : 0 }} €</td>
            <td><button @click="deleteItem(item)">Supprimer</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/config/config.js";
import { isTokenExpired } from "../../auth";
import OfferDetailPopup from "./../offers/OfferDetailPopup.vue";
import ItemDetailPopup from "./ItemDetailPopup.vue";
import LoaderDiv from "./../LoaderDiv.vue";

export default {
  components: {
    LoaderDiv,
    OfferDetailPopup,
    ItemDetailPopup
  },
  name: "ItemsList",
  data() {
    return {
      loadingIndex: null,
      items: [],
      platforms: [],
      loading: true,
      selectedItemKey: null,
      selectedItem: {},
      selectedOffer: {},
      isOfferModalVisible: false,
      isItemModalVisible: false,
      currentPage: 0,
      limit: config.itemsLimit,
      cdnUrl: config.cdnUrl,
      totalItems: 0,
      allItemsLoaded: false,
      filters: {
        offerId: '',
        type: '',
        sold: '',
        name: '',
        platform: ''
      },
    };
  },
  async mounted() {
    const token = localStorage.getItem("access_token");
    if (isTokenExpired(token)) {
      this.$router.push("/");
    }

    await this.loadPlatforms();
    await this.loadItems();
  },
  methods: {
    async deleteItem(item) {
      if (confirm("Voulez-vous vraiment supprimer cet objet ?")) {
        try {
          await axios.delete(`${config.apiUrl}/items/${item._key}`);
          this.items = this.items.filter(i => i._key !== item._key);
        } catch (error) {
          console.error("Erreur lors de la suppression de l'objet :", error);
        }
      }
    },

    async filterItems() {
      this.currentPage = 0;
      this.loading = false;
      this.allItemsLoaded = false;
      this.items = [];
      this.selectedItem = {};
      this.selectedOffer = {};
      await this.loadItems()
    },
    async showOfferDetails(offer_id) {

      // Affiche le loader
      this.loading = true;

      try {
        // Appel à l'API pour récupérer les détails de l'élément
        const response = await axios.get(
          `${config.apiUrl}/offers/${offer_id}`
        );

        this.selectedOffer = response.data;
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des détails de l'annonce :",
          error
        );
      } finally {
        this.isOfferModalVisible = true;
        this.loading = false;
      }
    },
    async showItemDetails(item_key) {
      this.selectedItemKey = item_key;
    },
    savedItem(item) {
      this.items = this.items.map(i => {
        if (i._key === item._key) {
          return item;
        }
        return i;
      });
      
    },
    async loadPlatforms() {
      try {

        const response = await axios.get(
          `${config.apiUrl}/lists/platforms`
        );

        // Append new results
        this.platforms.push(...response.data);

      } catch (error) {
        console.error("Erreur lors de la récupération des plateforms:", error);
      } 
    },
    async loadItems() {

      // Ensure not all offers and not loading in progress
      if (this.allItemsLoaded) return;

      const token = localStorage.getItem("access_token");
      if (isTokenExpired(token)) {
        this.$router.push("/");
      }

      // Ajout des filtres aux paramètres de la requête
      const params = new URLSearchParams();
      if (this.filters.offerId) {
        params.append('offerId', this.filters.offerId);
      }
      if (this.filters.type) {
        params.append('type', this.filters.type);
      }
      if (this.filters.sold) {
        params.append('sold', this.filters.sold);
      }   
      if(this.filters.name) {
        params.append('name', this.filters.name);
      }
      if(this.filters.platform) {
        params.append('platform', this.filters.platform);
      }      

      try {
        // Activer le loader
        this.loading = true;

        const response = await axios.get(
          `${config.apiUrl}/items?page=${this.currentPage}&limit=${this.limit}&${params.toString()}`
        );

        // Append new results
        this.items.push(...response.data.result);

        // Get statistics
        this.totalItems = response.data.total_items;
        this.currentPage += 1;

        // Vérifier si tous les éléments ont été chargés
        if (this.items.length >= this.totalItems) {
          this.allItemsLoaded = true;
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des offres:", error);
      } finally {
        this.loading = false;
      }
    },

    // Gestion du scroll infini
    handleScroll() {
      const mainContent = this.$refs.mainContentItems;
      const itemsTable = this.$refs.itemsTable;

      if (!mainContent || !itemsTable || this.allItemsLoaded) return;

      // Vérifier si l'utilisateur a atteint la fin du tableau (tbody)
      const tableBottomReached =
        mainContent.scrollTop + mainContent.clientHeight >= itemsTable.clientHeight;

      if (tableBottomReached) {
        this.loadItems(); // Charger plus d'éléments quand on arrive à la fin du tableau
      }
    },
  }
};
</script>

<style scoped>
.filters {
  margin-top: 10px;
  margin-bottom: 5px;
}

.input-filter {
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.count {
  margin-top: 10px;
  font-size: 11pt;
  font-weight: bold
}

.thumbnail {
  float: left;
  margin: 0px 15px 0px 0px;
  max-height: 37px;
}

.ad-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  margin: 10px 0;
}

.items-list-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* Prendre toute la hauteur de la fenêtre */
}

.main-content-items {
  width: auto;
  height: calc(100vh - 160px);
  /* flex-grow: 1; */
  /* Ajustez la hauteur pour permettre le défilement */
  overflow-y: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 0px;  
}

th,
td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  /* background-color: #f2f2f2; */
  font-weight: normal;
  background-color: #3498db;
  color: white;
}

button {
  padding: 6px 12px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #ff1a1a;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  text-align: center;
  font-size: 18px;
  color: #3498db;
}
</style>
