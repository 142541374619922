<template>
    <div>
        <!-- Contenu de la page -->
        <LoaderDiv :isLoading="loading" loaderText="Chargement en cours, veuillez patienter..." />

        <div class="dashboard">
        <!-- KPI Section -->
        <div class="kpi-section">
            <div class="kpi-item">
                <h3>Offres suivies</h3>
                <p>{{ dashboard.offers_total }}</p>
            </div>
            <div class="kpi-item">
                <h3>Offres gagnées</h3>
                <p>{{ dashboard.offers_owned }}</p>
            </div>
            <div class="kpi-item">
                <h3>Cout d'achat</h3>
                <p>{{ formattedNumber(dashboard.costs.total) }}€</p>
            </div>
        </div>
        <div class="kpi-section">
            <div class="kpi-item">
                <h3>Objets détenus</h3>
                <p>{{ dashboard.items_owned }}</p>
            </div>
            <div class="kpi-item">
                <h3>Objets vendus</h3>
                <p>{{ dashboard.sold.items }}</p>
            </div>
            <div class="kpi-item">
                <h3>Montant vendu</h3>
                <p>{{ formattedNumber(dashboard.sold.value) }}€</p>
            </div>
        </div>

        <div class="kpi-section">
            <div class="kpi-item">
                <h3>Offres "vendues"</h3>
                <p>{{ dashboard.sold.offers_linked }}</p>
            </div>
            <div class="kpi-item">
                <h3>Valeur des offres "vendues"</h3>
                <p>{{ formattedNumber(dashboard.sold.offers_linked_value) }}€</p>
            </div>
            <div class="kpi-item">
                <h3>Gain/Perte sur les offres "vendues"</h3>
                <p>{{ formattedNumber(dashboard.sold.value - dashboard.sold.offers_linked_value) }}€</p>
            </div>
        </div>        

        <!-- Charts Section -->
        <div class="charts-section">
            <div class="chart-item">
                <h3>Jeux</h3>
                <pie-chart :data="pieChartData.games"></pie-chart>
            </div>
            <div class="chart-item">
                <h3>Modèles</h3>
                <pie-chart :data="pieChartData.models"></pie-chart>
            </div>
        </div>

        <!-- <div class="tops-section">
            <top-items :items="dashboardData.tops.models" title="Top Models"></top-items>
            <top-items :items="dashboardData.tops.games" title="Top Games"></top-items>
            <top-items :items="dashboardData.tops.devices" title="Top Devices"></top-items>
        </div> -->
        </div>
    </div>
</template>

<script>

// Importer le composant TopItems pour les Top Models et Games
import axios from 'axios';
import { isTokenExpired } from '../auth';
import LoaderDiv from './LoaderDiv.vue';
// import TopItems from './charts/TopItems.vue';
import config from '@/config/config.js';

export default {
    components: {
        LoaderDiv,        
        // TopItems
    },
    data() {
        return {
            loading: true,
            dashboard : {
                costs: {},
                sold:{}
            },
            dashboardData: {
                orders: {
                    total: 123
                },
                items: {
                    total: 83
                },
                money: {
                    spent: 1255
                },
                tops: {
                    models: [
                        { name: 'Gamecube', items: 20 },
                        { name: 'Super Famicom', items: 15 },
                        { name: 'Famicom', items: 10 }
                    ],
                    games: [
                        { name: 'Zelda', items: 5 },
                        { name: 'Mario Party 5', items: 15 },
                        { name: 'Street Fighters', items: 2 }
                    ],
                    devices: [
                        { name: 'Manette', items: 50 },
                        { name: 'Alimentation', items: 15 },
                        { name: 'Autre', items: 2 }
                    ]
                }
            },
            pieChartData: {
                models: [],
                games: [],
                devices: [
                    { label: 'Zelda', value: 5 },
                    { label: 'Mario Party 5', value: 15 },
                    { label: 'Street Fighters', value: 2 }
                ]
            }
        };
    },
    async created() {
        const token = localStorage.getItem('access_token');
        if (isTokenExpired(token)) {
            this.$router.push('/');
        }

        try {
        this.loading = true;
        const response = await axios.get(`${config.apiUrl}/dashboards`);
        this.dashboard = response.data;
        this.pieChartData.models = this.dashboard.charts.models.map(item => [item.name, item.items]);
        this.pieChartData.games = this.dashboard.charts.games.map(item => [item.name, item.items]);        

        } catch (error) {
            console.error("Erreur lors de la récupération des jobs:", error);
        } finally {
            this.loading = false;
        }
    }, 
    methods: {
        formattedNumber(number) {
            return new Intl.NumberFormat('fr-FR', {
                style: 'decimal',
                maximumFractionDigits: 2, // Limiter à 2 décimales
                minimumFractionDigits: 2, // Assurer qu'il y a toujours 2 décimales
            }).format(number);
        }        
    }   
};
</script>

<style scoped>
.dashboard {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.kpi-section {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.kpi-item {
    flex: 1;
    background: #f4f4f4;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
}

.charts-section {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.chart-item {
    flex: 1;
    background: #f4f4f4;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
}

.tops-section {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}
</style>
